@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
}
.screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-wrapper {
  width: 386px;

  position: relative;
}

.card {
  width: 100%;
}

.card-header {
  text-align: center;
  padding: 12px 0;
  background: #6cb372;
}

.card-header span {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.card-body-wrapper {
  padding: 20px 16px 20px 16px;
}

.card-body-wrapper .card-body {
  border-radius: 40px 40px 0 0;
  border: 0.5px solid #cecece;
  overflow: hidden;
}

.card-body-bg {
  height: 100px;
}

.card-body-bg img {
  height: inherit;
  width: 100%;
  object-fit: cover;
}

.avatar {
  position: absolute;
  top: 134px;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
}

.avatar img {
  border: 2px solid #cecece;
  border-radius: 12px;
  width: 60px;
  object-fit: cover;
}

.typography {
  padding: 40px 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.typography .name-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.typography .name-heading .name {
  font-weight: 700;
  font-size: 20px;
}

.typography .name-heading img {
  width: 20px;
}

.typography .desc {
  font-weight: 500;
  margin-top: 4px;
  padding: 0 10px;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icon {
  background: #cecece;
  border-radius: 4px;
}

.social {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.social-link {
  width: 312px;
  border: 1px solid #cecece;
  border-radius: 15px;
  padding: 14px 12px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.social-link img {
  width: 38px;
}

.social-link span {
  margin-right: auto;
}

.social-link svg {
  font-weight: 500;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 20px;
  cursor: pointer;
}
.btn button {
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #6cb372;
  border-radius: 15px;
  width: 312px;
  padding: 16px 0;
  color: #fff;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

@media (max-width: 540px) {
  .card-wrapper {
    width: 100%;
  }
}

/* Form */

.form-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.form-content {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-content .heading {
  color: #09080f;
  font-size: 16px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-field label {
  color: #353540;
  font-size: 14px;
  font-weight: 500;
}

.form-control {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  background: #e6e6e7;
  border-radius: 8px;
}

.form-control input {
  width: 236px;
  outline: none;
  border: none;
  background: transparent;
}

.form-control input::placeholder {
  font-family: "Montserrat", sans-serif;
}

form button {
  width: 100%;
  border-radius: 14px;
  margin-top: 10px;
  padding: 16px 0;
  border: none;
  outline: none;
  background-color: #5cdf51;
  font-size: 17px;
  cursor: pointer;
}

.error {
  font-size: 12px;
  color: red;
}

.loading-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  color: #5cdf51;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.response-wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.response-card {
  box-shadow: 0 0 8px #faf9f6;
  padding: 20px;
  display: flex;
  gap: 12px;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

// add-new-css

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border-radius: 5px;
}

.white-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  color: #000;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  .error-message {
    color: red;
    font-size: 12px;
    font-weight: 600;
  }

  .form {
    display: flex;
    gap: 6px;
    flex-direction: column;
    color: #000;
    text-align: left;
    label {
      font-size: 13px;
      font-weight: 600;
      margin-top: 10px;
    }
    input {
      padding: 16px 20px;
      color: #000;
      border-radius: 50px;
      border: 1px solid #dcdcdc;
      outline: none;
      background-color: #fff;
      ::placeholder {
        color: #dcdcdc;
      }
    }
  }
}

.theme-btn {
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #FFA845;
  color: #fff;
  font-weight: 600;
  width: 100%;
  margin-top: 20px;
}

.section {
  height: 100%;
  overflow-y: scroll;

  max-width: 500px;
  margin: 0 auto;

  .profile {
    display: flex;
    align-items: center;
    gap: 10px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 10px;

    .social-icon {
      display: flex;
      gap: 6px;
      align-items: center;
      img {
        padding: 4px;
        width: 30px;
        height: 30px;
        border: 1px solid #fff;
        border-radius: 50%;
      }
    }
  }
}
.link-section {
  padding: 20px 0px 20px 30px;
  border-bottom: 1px solid #73a0f8;
  &.border-0 {
    border-bottom: none;
  }
  .link-header {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #73a0f8;
    padding-bottom: 20px;
    gap: 10px;
    color: #000;
    font-weight: 600;
    font-size: 18px;
  }
}

.outer {
  padding: 20px 20px 0px;
  color: #000;
}

a {
  text-decoration: none;
  color: #000;
}
.video-section {
  padding: 20px 20px 0px 0px;
  position: relative;
  video {
    width: 100%;
    border-radius: 26px;
  }
  img {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 20px 18px 0px 0px;
  flex-wrap: wrap;

  .socialLinkCard {
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    background-color: #355ff833;
    img {
      background-color: #fff;
      border-radius: 50%;
      padding: 10px;
      width: 50px;
      height: 50px;
    }
    .title {
      color: #000;
      font-weight: 600;
    }
    .link {
      color: #000;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

// add-new-css
